
import React from "react"
import {Products } from "../utils/product"
import ComboTemplate from "../components/Templates/ComboTemplate"

const ComboFlynow = () => {
  const data = {
    id: "combo_app_curso_habito",
    title: "PREMIUM VITALÍCIO + CURSO HÁBITOS",
    discontPercentage: 25,
    URL_CHECKOUT: "https://buy.stripe.com/7sI15RdSF7y55t6dR6?locale=pt-BR&prefilled_promo_code=FLYN25",
    cta: "ADQUIRIR COMBO PROMOCIONAL",
    sections: [
      {
        section: "APLICATIVO (PLANO PREMIUM VITALÍCIO)",
        data: [Products.apps[0]]
      },
      {
        section: "CURSO DE HÁBITOS",
        data: [Products.cursos[1]]
      },
    ],
  }
  return <ComboTemplate  {...data} />
}
export default ComboFlynow